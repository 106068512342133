
import React from "react";
import { useTranslation } from "react-i18next";
const Home = () => {
	const [t, i18n] = useTranslation();

	return (
		<div className="lerna-home">
		<div id="hero-and-body" style={{ marginTop: "-120px", position: "absolute", top: "40%", left: "0%", width: "100%", display: "flex", justifyContent: "center" }}>
			<div id="page-body" style={{width: "70%", paddingTop: "100px"}}>
 
						<div id="portfolio-grid1"
							className="slist portfolio-list not-spaced text-left captionpos-right block-align hide-headerfooter trans-fade fade-hide slist-container portfolio-container"
							data-ratio="3:2">
							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__bounceIn animate__delay-1s slist-item portfolio-item cat-10 item-16 do-anim-text animated"
								data-item="item-16">
								<a href="/individual" data-fade="#fff" data-appearance="dark">
									<h4 className="portfolio-name h1 lerna-orange">{t('individual')}</h4>
									<span className="caption-sub portfolio-category h5">{t('lessons')}</span>
								</a>
							</div>
							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__backInRight slist-item portfolio-item cat-10 item-46 do-anim-text animated"
								data-item="item-46">
								<a href="/corporate" data-fade="#fff" data-appearance="light">
									<h4 className="portfolio-name h1">{t('corporate')}</h4>
									<span className="caption-sub portfolio-category h5">{t('programs')}</span>
								</a>
							</div>

							<br />
							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__backInLeft slist-item portfolio-item cat-12 item-336 do-anim-text animated"
								data-item="item-336">
								<a href="/whoarewe" data-fade="#fff" data-appearance="light">
									<h4 className="portfolio-name h1 lerna-silver">{t('whoarewe')}</h4>
									<span className="caption-sub portfolio-category h5">{t('ourteam')}</span>
								</a>
							</div>
							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__backInDown slist-item portfolio-item cat-12 item-461 do-anim-text animated"
								data-item="item-461">
								<a href="/personalized" data-fade="#fff" data-appearance="light">
									<h4 className="portfolio-name h1">{t('personalized')}</h4>
									<span className="caption-sub portfolio-category h5">{t('solutions')}</span>
								</a>
							</div>
							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__backInRight slist-item portfolio-item cat-12 item-189 do-anim-text animated"
								data-item="item-189">
								<a href="/approach" data-fade="#fff" data-appearance="light">
									<h4 className="portfolio-name h1 lerna-purple">{t('innovative')}</h4>
									<span className="caption-sub portfolio-category h5">{t('approach')}</span>
								</a>
							</div>

							<br />

							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__backInUp slist-item portfolio-item cat-11 item-44 do-anim-text "
								data-item="item-44">
								<a href="/exams" data-fade="#fff" data-appearance="light" id="interactive-btn">
									<h4 className="portfolio-name h1 lerna-yellow">{t('exams')}</h4>
									<span className="caption-sub portfolio-category h5">{t('preparation')}</span>
								</a>
							</div>
							<div  style={{ paddingLeft: "0%", paddingTop: "25px" }} className="animate__animated animate__backInLeft slist-item portfolio-item cat-11 item-461 do-anim-text animated"
								data-item="item-461">
								<a href="/flexible" data-fade="#fff" data-appearance="light">
									<h4 className="portfolio-name h1">{t('flexible')}</h4>
									<span className="caption-sub portfolio-category h5">{t('hours')}</span>
								</a>
							</div>

							<div className="slist-hover-media portfolio-hover-media size-full img-cover trans-fade">
								<div className="slist-media portfolio-media background-item-336" data-item="item-336">
								</div>
								<div className="slist-media portfolio-media background-item-461" data-item="item-461">
								</div>
								<div className="slist-media portfolio-media to-top background-item-189" data-item="item-189"
								>
								</div>
								<div className="slist-media portfolio-media background-item-16" data-item="item-16"
								>
								</div>
								<div className="slist-media portfolio-media background-item-46" data-item="item-46"
								>
								</div>
								<div className="slist-media portfolio-media background-item-44" data-item="item-44"
								>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
	)
};


export default Home;