import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
    const [t, i18n] = useTranslation();

    return (

        <div id="hero-and-body" className="about-page">
            <section id="hero" className="hero-auto no-bg type-default" >
                <div id="page-title" className="wrapper-medium  title-top align-left">
                    <h2 className="h1 animate__animated animate__fadeInUp">{t('about.about')}</h2>
                </div>

            </section>

            <div id="page-body" className="about-wrapper">
                <div className="wrapper-medium">
                    <div className="column-section clearfix spaced-big  col-align-top no-anim">
                        <div className="column hasbg col7 no-anim">
                            <div className="col-content">
                                <div className="row display-flex lerna-about">
                                    <div className="column col">
                                        <div className="card display-flex">
                                            <div>
                                                <div className="display-flex card-image">
                                                    <img src="../img/piwi.png" alt="Piwi" height="450" width="150" />
                                                    <div className="image-info">
                                                        <h4>Bahia Farhat</h4>
                                                        <p className="about-person-title">{t('about.title.1')}</p>
                                                    </div>
                                                </div>
                                                <p style={{textAlign: "justify"}}>{t('about.description.piwi')} </p>
                                                <p>bahia_farhat@yahoo.com</p>
                                                <p><a href="/reachout"><input type="button" value={t('about.contact')}  /></a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column" style={{display: "none"}}>
                                        <div className="card display-flex">

                                            <div>
                                                <div className="display-flex card-image">
                                                    <img src="../img/tata.png" alt="Tata" height="450" width="150" />
                                                    <div className="image-info">
                                                        <h4>Tarek Allam</h4>
                                                        <p className="about-person-title">{t('about.title.2')} </p>
                                                    </div>
                                                </div>
                                                <p>{t('about.description.tata')} </p>
                                                <p>tarek@lerna.ca</p>
                                                <p><a href="/reachout"><input type="button" value={t('about.contact')}  /></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    );

}

export default About;
