import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


function ShowTopMenu() {
    const path = useLocation().pathname;

    return (
        path == "/home" || path == "/connect" || path == "/reachout" || path == "/about" || path == "/schedule"
            ? false
            : true);

}


const TopMenu = () => {
    const path = useLocation().pathname;
    const [t, i18n] = useTranslation();

    return (
        <div className="top-menu animate__animated animate__fadeIn" style={ShowTopMenu() ? {} : { display: "none" }}>
            <div className="top-menu-wrapper">
                <div>
                    <a href="/individual">
                        <h5 className={path == "/individual" ? "top-menu-selected-color" : "top-menu-default-color"}>{t("individual")}</h5>
                    </a>
                </div>
                <div>
                    <a href="/corporate">
                        <h5 className={path == "/corporate" ? "top-menu-selected-color" : "top-menu-default-color"}>{t("corporate")}</h5>
                    </a>
                </div>
                <div>
                    <a href="/whoarewe">
                        <h5 className={path == "/whoarewe" ? "top-menu-selected-color" : "top-menu-default-color"}>
                            {t("whoarewe")}
                        </h5>
                    </a>
                </div>
                <div>
                    <a href="/personalized">
                        <h5 className={path == "/personalized" ? "top-menu-selected-color" : "top-menu-default-color"}>{t("topmenu.personalized")}</h5>
                    </a>
                </div>
                <div>
                    <a href="/approach">
                        <h5 className={path == "/approach" ? "top-menu-selected-color" : "top-menu-default-color"}>{t("topmenu.approach")}</h5>
                    </a>
                </div>
                <div>
                    <a href="/exams">
                        <h5 className={path == "/exams" ? "top-menu-selected-color" : "top-menu-default-color"}>{t("exams")}</h5>
                    </a>
                </div>
                <div>
                    <a href="/flexible">
                        <h5 className={path == "/flexible" ? "top-menu-selected-color" : "top-menu-default-color"}>{t("flexible")}</h5>
                    </a>
                </div>
            </div>
        </div>
    );
}


export default TopMenu;
