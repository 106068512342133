import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import "./css/main.css";
// import "./css/animate.css";
// import "./css/file1.css";
// import "./css/file2.css";
// import "./css/file3.css";
// import "./fonts/anton.css";
// import "./fonts/dmsans.css";
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loader from "./pages/Loader";
import Home from "./pages/Home";
import Connect from "./pages/Connect";
import ReachOut from "./pages/ReachOut";
import About from "./pages/About";
import Flexible from './pages/Flexible';
import Schedule from './pages/Schedule';
import Individual from './pages/Individual';
import Corporate from './pages/Corporate';
import WhoAreWe from './pages/WhoAreWe';
import Approach from './pages/Approach';
import Exams from './pages/Exams';
import Personalized from './pages/Personalized';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Loader} />
        <Suspense fallback={(<div></div>)}>
          <App>
            <Switch>
              <Route path="/home" exact component={Home} />
              <Route path="/reachout" exact component={ReachOut} />
              <Route path="/connect" exact component={Connect} />
              <Route path="/about" exact component={About} />
              <Route path="/schedule" exact component={Schedule} />

              <Route path="/whoarewe" exact component={WhoAreWe} />
              <Route path="/exams" exact component={Exams} />
              <Route path="/flexible" exact component={Flexible} />
              <Route path="/individual" exact component={Individual} />
              <Route path="/corporate" exact component={Corporate} />
              <Route path="/approach" exact component={Approach} />
              <Route path="/personalized" exact component={Personalized} />
            </Switch>
          </App>
        </Suspense>
      </Switch>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
