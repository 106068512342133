import React from "react";
import { useTranslation } from "react-i18next";

const WhoAreWe = () => {
    const [t, i18n] = useTranslation();

    return (
        <>
            <div id="hero-and-body" style={{ marginTop: "-100px" }} className="about-page animate__animated animate__zoomIn animate__faster" >

                <section id="hero" className="hero-auto no-bg type-default"  >
                    <div id="page-title" className="wrapper-medium  title-top align-left" style={{ marginLeft: "18rem" }}>
                        {/* <h2>Personalized</h2> */}
                    </div>
                </section>

                <div id="page-body" style={{ marginTop: "-180px", overflowX: "hidden", display: "flex", justifyContent: "center" }}>
                    <div className="tilesWrap animate__animated animate__fadeIn">
                        <div>
                            {/* <h2>.</h2> */}
                            <h4>{t("whoarewe.1.title")}</h4>
                            <p style={{textAlign: "justify"}}>
                                {t("whoarewe.1")}
                            </p>
                            {/* <button>Read more</button> */}
                        </div>
                    </div>


                </div>

            </div>

        </>


    );
}


export default WhoAreWe;
