const PageAnimation = () => {
    return (
        <>
            <div className="design-right animate__animated animate__backInDown right-sidebar"></div>

            <div className="design-left animate__animated animate__backInUp left-sidebar"></div>
        </>
    );
}

export default PageAnimation;