import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";



const Connect = () => {
    const [t, i18n] = useTranslation();

    const notify = () => toast.warn(t("valid.email.password"));


    return (

        <div id="hero-and-body">
            <section id="hero" className="hero-auto no-bg type-default" >
                <div id="page-title" className="wrapper-medium  title-top align-left">
                    <h2 className="h1 animate__animated animate__fadeInUp">{t('connect.connect')}</h2>
                </div>

            </section>

            <div id="page-body" className="connect-wrapper">
                <div className="wrapper-medium">
                    <div className="column-section clearfix spaced-big  col-align-top no-anim">
                        <div className="column one-half   hasbg col7 no-anim">
                            <div className="col-content">
                                <h5>{t('connect.description')} <a href="/reachout">{t('connect.here')}</a></h5>
                            </div>
                        </div>
                        <div className="column one-half last-col  col8 no-anim">
                            <div className="col-content">
                                <div role="form" className="wpcf7" id="wpcf7-f638-p229-o1" dir="ltr" lang="en-US">
                                    <form action="/server" method="post"
                                        className="wpcf7-form" >

                                        <div className="form-row column ">
                                            <div className="col-content">
                                                <label>{t('connect.email')}</label><span
                                                    className=""><input type="text"
                                                        size="40"
                                                    /></span>
                                            </div>
                                        </div>
                                        <div className="form-row column">
                                            <div className="col-content">
                                                <label>{t('connect.password')}</label><span
                                                    className="">
                                                    <input type="password"
                                                        size="40"
                                                    /></span>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                        <p>
                                            <ToastContainer position="bottom-right"
                                                autoClose={5000}
                                                hideProgressBar={true} 
                                                toastClassName="toast-container" />

                                            <input type="button" onClick={notify} value={t('connect.login')}
                                                className="wpcf7-form-control wpcf7-submit" />
                                            <span className="ajax-loader"></span>
                                        </p>
                                        <div className="wpcf7-response-output wpcf7-display-none">

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Connect;
