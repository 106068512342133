import React from "react";
import { useLocation } from "react-router-dom";

function ShowFooter() {
    const path = useLocation().pathname;
    return (
        path == "/home"
            ? true
            : true );

}


const Footer = () => {

    const className =  "animate__animated animate__fadeIn animate__delay-1s";

    return (
        <footer id="footer" className={className + " sticky"} style={ShowFooter() ? {} : {display: "none"}}>
            <div className="footer-inner wrapper">
                <div className="column-section spaced-big col-align-bottom clearfix">
                    <div className="column one-half tallest footer-min-height">
                        <div className="col-content footer-margin-top">
                            <div id="text-2" className="widget widget_text clearfix">
                                <div className="textwidget">
                                    <h6 className="h8"></h6>
                                    <ul className="socialmedia-widget text-style normal align-left">
                                        <li className="facebook"><a href="/home" target="_blank" rel="noopener">Lerna</a>
                                        </li>
                                        <li className="twitter"><a href="/home" target="_blank" rel="noopener">Progressive</a></li>
                                        <li className="instagram"><a href="/home" target="_blank" rel="noopener">Learning</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column one-half last-col footer-min-height">
                        <div className="col-content footer-copyright-margin">
                            <div id="text-3" className="widget widget_text clearfix">
                                <div className="textwidget">
                                    <p className="copyright">&copy; Copyright 2020, Lerna.ca</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;