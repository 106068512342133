import React from 'react';
import { useTranslation } from 'react-i18next';


const Header = () => {
    const [t, i18n] = useTranslation();
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng

    function toggleLanguage(){
        getLanguage() == "en" ? i18n.changeLanguage("fr") : i18n.changeLanguage("en");

    }

    return (<header id="header" className="sticky" style={{backgroundColor: "white"}}>
        <div className="header-inner clearfix wrapper">
            <div id="logo">
                <a href="/home">
                    <h5 className="main-logo-lerna">lerna<span
                        className="main-logo-dot">.</span>ca</h5>
                </a>
            </div>

            <div id="menu">
                <div id="menu-inner">
                    <div className="scroll-menu">
                        <nav id="main-nav" className="fade slide underline">
                            <ul id="primary-menu" className="">
                                <li id="menu-item-804"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-804">
                                    <a href="/about" data-fade="#fff" data-appearance="light"><span
                                        className="slide-hover" data-hover={t("about")}><span
                                            className="default"></span>{t("about")}</span></a>
                                </li>
                                <li id="menu-item-175"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-175">
                                    <a href="/reachout" data-fade="#fff" data-appearance="light"><span className="slide-hover"
                                        data-hover={t("reachout")}><span className="default">{t("reachout")}</span></span></a>
                                </li>
                                <li id="menu-item-232"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-232">
                                    <a href="/schedule" data-fade="#fff" data-appearance="light"><span className="slide-hover"
                                        data-hover={t("scheduler")}><span className="default">{t("scheduler")}</span></span></a>
                                </li>
    
                                <li id="" className="">
                                    <a href="/connect" data-fade="#fff" data-appearance="light"><span className="slide-hover"
                                        data-hover={t("connect")}><span className="default">{t("connect")}</span></span></a>
                                </li>
                            </ul>
                        </nav>

                    </div>

                </div>
                <div className="menu-toggle"><span className="hamburger"></span></div>
            </div>
            <div className="menu-language fade">
                <a href="#" onClick={toggleLanguage} className="">{t("toggleLanguage")}</a>
            </div>
        </div>
    </header>
    );

}

export default Header;
