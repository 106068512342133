
import { InlineWidget } from "react-calendly";

const Schedule = () => {
    return (
        <>
        <div id="hero-and-body">
                <section id="hero" className="hero-auto no-bg type-default" >
                    <div id="page-title" className="wrapper-medium  title-top align-left">
                        <h2 className="h1 animate__animated animate__fadeInUp"></h2>
                    </div>

                </section>

                <div id="page-body" style={{ marginTop: "-200px", width: "100%" }}>
                    <div className="wrapper-medium" >
                        <div className="column-section clearfix spaced-big  col-align-top no-anim" >
                            <div className="column one-full last-col  col12 no-anim" >
                                <InlineWidget url="https://calendly.com/lerna/schedule" /> 
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
};

export default Schedule;
