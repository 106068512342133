import React from "react";
// import "./css/main.css";
// import "./css/animate.css";
// import "./css/file1.css";
// import "./css/file2.css";
// import "./css/file3.css";
// import "./fonts/anton.css";
// import "./fonts/dmsans.css";
import Header from "./pages/Header";
import PageAnimation from "./pages/PageAnimation";
import Footer from "./pages/Footer";
import TopMenu from './components/TopMenu';

class App extends React.Component {

  render() {
    return (

      <body
        className="page-template-default page page-id-148 lerna-theme thepage-148 mouse-active loaded scrolled-hero loading-end">
        <PageAnimation />
        <Header />
        <TopMenu />
        {this.props.children}

        <Footer />
      </body>

    );
  }
}

export default App;
