import React from "react";
// import "../css/main.css";
// import "../css/animate.css";

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    handleLoad() {
        setTimeout(function () { window.location.href = "/home" }, 5000);
    }

    render() {
        return (
            <div className="container">
                <div className="box">
                    <div className="title loader-title-font">
                        <span className="block"></span>
                        <h1 className="loader-logo-font">lerna<div className="animate__animated animate__bounceIn animate__delay-2s lerna-yellow"
                        >.</div>ca</h1>
                    </div>

                    <div className="role loader-role-margin">
                        <div className="block"></div>
                        <p className="loader-p-font">A <b className="loader-b-font">progressive</b> <br />learning experience</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Loader;
