import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { BsFillEnvelopeFill, BsFillTelephoneFill } from "react-icons/bs";

const ReachOut = () => {
    const [t, i18n] = useTranslation();

    const notify = () => toast.warn(t("fill.out.form"));

    const reachOut = () => {
        const inputFeilds = document.querySelectorAll("input");

        const validInputs = Array.from(inputFeilds).filter(input => input.value !== "");

        if (validInputs.length == 3) {
            var name = document.getElementById("reachout-name").value;
            var email = document.getElementById("reachout-email").value;
            var level = document.getElementById("reachout-level").value;
            var exam = document.getElementById("reachout-exam").value;
            var message = document.getElementById("reachout-message").value;

            fetch("https://us-central1-lerna-323415.cloudfunctions.net/lerna-notifications?name="+name+"&email="+email+"&level="+level+"&exam="+exam+"&message="+message)
            .then(function() {
                document.getElementById("reachout-form").innerHTML = t('reachout.message.sent');
            }).catch(function(error) {
                console.log(error);
            });
        }
        else
            notify()
    }

    return (

        <div id="hero-and-body">
            <section id="hero" className="hero-auto no-bg type-default" >
                <div id="page-title" className="wrapper-medium  title-top align-left">
                    <h2 className="h1 animate__animated animate__fadeInUp">{t('reachout.reachout')}</h2>
                </div>

            </section>
{/* 
            <div id="page-body" className="reach-out-wrapper">
                <div className="wrapper-medium">
                    <div className="column-section clearfix spaced-big  col-align-top no-anim">
                        <div className="column one-half   hasbg col7 no-anim">
                            <div className="col-content">
                                <h5>{t('reachout.description')}</h5>
                            </div>
                        </div>
                        <div className="column one-half last-col  col8 no-anim">
                            <div className="col-content">
                                <div role="form" className="wpcf7" id="wpcf7-f638-p229-o1" dir="ltr" lang="en-US">
                                    <div className="screen-reader-response"></div>
                                    <form id="reachout-form" action="/server" method="post"
                                        className="wpcf7-form" novalidate="novalidate">

                                        <div className="form-row column one-half">
                                            <div className="col-content">
                                                <label>{t('reachout.name')}</label><span
                                                    className="wpcf7-form-control-wrap your-name"><input id="reachout-name" type="text"
                                                        size="40"
                                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                        aria-required="true" aria-invalid="false" /></span>
                                            </div>
                                        </div>
                                        <div className="form-row column one-half last-col">
                                            <div className="col-content">
                                                <label>{t('reachout.email')}</label><span
                                                    className="wpcf7-form-control-wrap your-email"><input id="reachout-email" type="email"
                                                        size="40"
                                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                        aria-required="true" aria-invalid="false" /></span>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                        <div className="form-row column one-half">
                                            <div className="col-content">
                                                <label>{t('reachout.level')}</label><span className="wpcf7-form-control-wrap studio">
                                                    <select
                                                        id="reachout-level"
                                                        name="studio" className="wpcf7-form-control wpcf7-select"
                                                        aria-invalid="false">
                                                        <option value=""></option>
                                                        <option value="beginner">{t('reachout.level.beginner')}</option>
                                                        <option value="intermediate">{t('reachout.level.intermediate')}</option>
                                                        <option value="advanced">{t('reachout.level.advanced')}</option>
                                                    </select></span>
                                            </div>
                                        </div>
                                        <div className="form-row column one-half last-col">
                                            <div className="col-content">
                                                <label>{t('reachout.exam')}</label><span className="wpcf7-form-control-wrap studio">
                                                    <select
                                                    id="reachout-exam"
                                                    name="studio" className="wpcf7-form-control wpcf7-select"
                                                    aria-invalid="false">
                                                    <option value=""></option>
                                                    <option value="oqlf">OQLF</option>
                                                    <option value="tefaq">TEFAQ</option>
                                                    <option value="tfi">TFI</option>
                                                    <option value="tef">TEF</option>
                                                    <option value="other">{t('reachout.exam.other')}</option>
                                                </select></span>
                                            </div>
                                        </div>
                                        <div className="clear"></div>

                                        <div className="form-row column one-full">
                                            <div className="col-content">
                                                <label>{t('reachout.message')}</label><span
                                                    className="wpcf7-form-control-wrap your-message">
                                                        <textarea
                                                        id="reachout-message"
                                                        cols="40" rows="5"
                                                        className="wpcf7-form-control wpcf7-textarea"
                                                        aria-invalid="false"></textarea></span>
                                            </div>
                                        </div>
                                        <p>
                                            <ToastContainer position="bottom-right"
                                                autoClose={5000}
                                                hideProgressBar={true}
                                                toastClassName="toast-container" />
                                            <input type="button" value={t('reachout.send')} onClick={reachOut}
                                                className="wpcf7-form-control wpcf7-submit" /><span
                                                    className="ajax-loader"></span></p>
                                        <div className="wpcf7-response-output wpcf7-display-none"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div id="page-body" className="reach-out-wrapper">
                <div className="wrapper-medium">
                    <div className="column-section clearfix spaced-big  col-align-top no-anim">
                        <div className="column   hasbg col12 no-anim">
                            <div className="col-content">
                                <h5>{t('reachout.description')}</h5>
                            </div>
                            <div className="contact-info col12">
                                <div className="email">
                                    <div><h4><BsFillEnvelopeFill /> </h4></div>
                                    <div style={{marginTop: "20px"}}><a href="mailto:bahia@lerna.ca"> bahia@lerna.ca</a></div>
                                </div>
                                <div className="phone col12">

                                    <div><h4><BsFillTelephoneFill /> </h4></div>
                                    <div style={{marginTop: "20px"}}><a>+1 (438) 778-0087</a></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default ReachOut;
