import { useTranslation } from "react-i18next";

// export default Flexible;
const Flexible = () => {
    const [t, i18n] = useTranslation();

    return (
        <>
            <div id="hero-and-body" style={{ marginTop: "-100px" }} className="about-page animate__animated animate__zoomIn animate__faster" >

                <section id="hero" className="hero-auto no-bg type-default"  >
                    <div id="page-title" className="wrapper-medium  title-top align-left" style={{ marginLeft: "18rem" }}>
                        {/* <h2>Flexible</h2> */}
                    </div>
                </section>

                <div id="page-body" style={{ marginTop: "-180px", overflowX: "hidden", display: "flex", justifyContent: "center" }}>
                    <div className="tilesWrap animate__animated animate__fadeIn">
                        <div style={{textAlign: "center"}}>
                            {/* <h2>.</h2> */}
                            <h4>{t("flexible.1.title")}</h4>
                            <p>
                            {t("flexible.1")} <br />
                            {t("flexible.2")} <hr />
                                <div className="animate__animated animate__shakeX animate__delay-3s">
                                   <a href="/schedule"> <input type="button" value="Book a demo"
                                        className="wpcf7-form-control wpcf7-submit" /></a>

                                </div>
                            </p>
                            {/* <button>Read more</button> */}
                        </div>

                    </div>


                </div>

            </div>

        </>


    );
}

export default Flexible;
